<template>
	<div class="card">
		<ul class="list-group">
			<li
				v-for="step in steps"
				:key="step.id"
				class="list-group-item"
				:class="{
					'active-custom text-success': isActiveStep(step),
				}"
				style="display: ruby">
				<div
					class="indicator-circle"
					:class="stepClass(step)">
					<template v-if="stepCompleted(step)">
						<i class="fas fa-check" />
					</template>
					<template v-else>
						<span>{{ stepNumber(step) }}</span>
					</template>
				</div>
				<span class="ml-2">
					{{ translate(`${step.attributes.code_name}_step`) }}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { Onboarding, Profile } from '@/translations';

export default {
	name: 'StepList',
	messages: [Profile, Onboarding],
	props: {
		steps: {
			type: Array,
			required: true,
		},
		activeStep: {
			type: String,
			default: 'update_account_information',
		},
	},
	methods: {
		isActiveStep(step) {
			return step.attributes.code_name === this.activeStep;
		},
		stepCompleted(step) {
			return step.attributes.completed;
		},
		stepNumber(step) {
			return step.attributes.number;
		},
		stepClass(step) {
			const isActive = step.attributes.code_name === this.activeStep;
			const isCompleted = step.attributes.completed;

			return {
				'circle-completed': isCompleted,
				'bg-success text-white': isActive,
				'circle-inactive': !isActive && !isCompleted,
			};
		},
	},
};
</script>

<style scoped>
.indicator-circle {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

.circle-completed {
	background-color: #28a745;
	color: white;
}

.active-custom {
	background-color: #f6eeeb;
	border-color: #f6eeeb;
	font-weight: bold;
}

.circle-active {
	background-color: #007bff;
	color: white;
}

.circle-inactive {
	background-color: #f8f9fa;
	color: #6c757d;
}
</style>
